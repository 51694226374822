// actions
import {
  updateSubscribeFormOverlay,
  updatePositionFormOverlay,
  updateContactsFormOverlay,
} from "../store/actions/base";
// constants
import { API, FORM_MESSAGES, FILE_INPUT_DEFAULT_MESSAGE } from "../settings";
// helpers
import { translator } from "./lang";

export async function sendSubscribeForm(dispatch, form, langCurrent, setSubscribeFormStatus) {
  updateSubscribeFormOverlay(dispatch, true);

  let formData = new FormData(form);

  fetch(API.SEND_SUBSCRIBE_FORM, {
    method: "post",
    body: formData,
  })
    .then(res => res.json())
    .then(res => {
      let data = [];

      data.isOk = res.status === "ok";

      switch (res.code) {
        case "email_empty":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.email_empty.es,
            en: FORM_MESSAGES.email_empty.en,
          });
          break;
        case "email_incorrect":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.email_incorrect.es,
            en: FORM_MESSAGES.email_incorrect.en,
          });
          break;
        case "db_connection_failed":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.db_connection_failed.es,
            en: FORM_MESSAGES.db_connection_failed.en,
          });
          break;
        case "email_exist":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.email_exist.es,
            en: FORM_MESSAGES.email_exist.en,
          });
          break;
        case "insert_broken":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.insert_broken.es,
            en: FORM_MESSAGES.insert_broken.en,
          });
          break;
        case "email_added":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.email_added.es,
            en: FORM_MESSAGES.email_added.en,
          });
          break;
        default:
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.db_connection_failed.es,
            en: FORM_MESSAGES.db_connection_failed.en,
          });
          break;
      }

      setSubscribeFormStatus(data);
      updateSubscribeFormOverlay(dispatch, false);
    })
    .catch(error => {
      let data = [];
      data.isOk = false;
      data.message = translator(langCurrent, {
        es: FORM_MESSAGES.db_connection_failed.es,
        en: FORM_MESSAGES.db_connection_failed.en,
      });

      setSubscribeFormStatus(data);
      updateSubscribeFormOverlay(dispatch, false);
    });
}

export function checkAllInputs(form, langCurrent = "en") {
  let inputs = form.current;
  let isAllCheckedForm = true;

  for (let input of inputs) {
    if (!checkForm(input, false, langCurrent)) {
      isAllCheckedForm = false;
    }
  }

  return isAllCheckedForm;
}

export async function sendContactsForm(dispatch, form, inputsFormData, langCurrent, amount, setContactsFormStatus) {
  updateContactsFormOverlay(dispatch, true);
  fetch(API.SEND_CONTACTS_FORM, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(inputsFormData),
  })
    .then(res => res.json())
    .then(res => {
      let data = [];
      data.isOk = res.status === "ok";
 
      switch (res.code) {
        case "contacts_form_sent":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.contacts_form_sent.es,
            en: FORM_MESSAGES.contacts_form_sent.en,
          });
          break;
        case "contacts_form_failed":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.contacts_form_failed.es,
            en: FORM_MESSAGES.contacts_form_failed.en,
          });
          break;
        case "db_connection_failed":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.db_connection_failed.es,
            en: FORM_MESSAGES.db_connection_failed.en,
          });
          break;
        case "email_added":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.email_added.es,
            en: FORM_MESSAGES.email_added.en,
          });
          break;
        case "email_incorrect":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.email_incorrect.es,
            en: FORM_MESSAGES.email_incorrect.en,
          });
          break;
        case "other":
          data.message = res.other_message;
          break;
        default:
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.db_connection_failed.es,
            en: FORM_MESSAGES.db_connection_failed.en,
          });
          break;
      }
      
      setContactsFormStatus(data);
      updateContactsFormOverlay(dispatch, false);
      form.reset();
      amount.value = ""
    })
    .catch(error => {
      console.error(error)
      
      let data = [];
      data.isOk = false;
      data.message = translator(langCurrent, {
        es: FORM_MESSAGES.db_connection_failed.es,
        en: FORM_MESSAGES.db_connection_failed.en,
      });
      
      setContactsFormStatus(data);
      updateContactsFormOverlay(dispatch, false);
    });
}

export async function sendPositionForm(dispatch, form, langCurrent, setPositionFormStatus) {
  updatePositionFormOverlay(dispatch, true);

  let formData = new FormData(form);

  fetch(API.SEND_POSITION_FORM, {
    method: "post",
    body: formData,
  })
    .then(res => res.json())
    .then(res => {
      let data = [];
      data.isOk = res.status === "ok";

      switch (res.code) {
        case "position_form_sent":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.position_form_sent.es,
            en: FORM_MESSAGES.position_form_sent.en,
          });
          break;
        case "db_connection_failed":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.db_connection_failed.es,
            en: FORM_MESSAGES.db_connection_failed.en,
          });
          break;
        case "email_added":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.email_added.es,
            en: FORM_MESSAGES.email_added.en,
          });
          break;
        case "email_incorrect":
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.email_incorrect.es,
            en: FORM_MESSAGES.email_incorrect.en,
          });
          break;
        case "other":
          data.message = res.other_message;
          break;
        default:
          data.message = translator(langCurrent, {
            es: FORM_MESSAGES.db_connection_failed.es,
            en: FORM_MESSAGES.db_connection_failed.en,
          });
          break;
      }

      setPositionFormStatus(data);
      updatePositionFormOverlay(dispatch, false);
    })
    .catch(error => {
      let data = [];
      data.isOk = false;
      data.message = translator(langCurrent, {
        es: FORM_MESSAGES.db_connection_failed.es,
        en: FORM_MESSAGES.db_connection_failed.en,
      });

      setPositionFormStatus(data);
      updatePositionFormOverlay(dispatch, false);
    });
}

export function checkForm(event, isEvent = true, langCurrent = "en") {
  let element;

  if (!isEvent) {
    element = event;
  } else {
    element = event.target;
  }

  let isAllChecked = true;

  switch (element.name) {
    case "first_name":
      element.value = element.value.replace(/([0-9])/g, "");

      if (
        String(element.value).length < 1 ||
        String(element.value).length > 60
      ) {
        element.parentNode.dataset.checked = false;
        isAllChecked = false;
      } else {
        element.parentNode.dataset.checked = true;
      }

      break;
  
    case "phone":
      element.value = element.value.replace(/[^0-9]/g, "");
    
      if (
        String(element.value).length < 1 ||
        String(element.value).length > 40
      ) {
        element.parentNode.dataset.checked = false;
        isAllChecked = false;
      } else {
        element.parentNode.dataset.checked = true;
      }
      
      break;
  
    case "amount":
      element.value = element.value.replace(/[^0-9]/g, "");
    
      if (!element.value) {
        element.parentNode.dataset.checked = false;
        isAllChecked = false;
      } else {
        element.parentNode.dataset.checked = true;
      }
    
      break;

    case "last_name":
      element.value = element.value.replace(/([0-9])/g, "");

      if (
        String(element.value).length < 1 ||
        String(element.value).length > 60
      ) {
        element.parentNode.dataset.checked = false;
        isAllChecked = false;
      } else {
        element.parentNode.dataset.checked = true;
      }

      break;

    case "login":
      element.value = element.value.replace(/\s+/g, "");

      if (
        String(element.value).length < 4 ||
        String(element.value).length > 60
      ) {
        element.parentNode.dataset.checked = false;
        isAllChecked = false;
      } else if (String(element.value).match(/[^a-zA-Z0-9\-_.]+/)) {
        element.parentNode.dataset.checked = false;
        isAllChecked = false;
      } else {
        element.parentNode.dataset.checked = true;
      }

      break;

    case "email":
      element.value = String(element.value).replace(/\s+/g, "");

      if (!String(element.value).length) {
        element.parentNode.dataset.checked = false;
        element.nextSibling.innerHTML = translator(langCurrent, {
          es: "El campo es obligatorio",
          en: "The field is required",
        });
        isAllChecked = false;
        break;
      } else if (!validateEmail(String(element.value))) {
        element.parentNode.dataset.checked = false;
        element.nextSibling.innerHTML = translator(langCurrent, {
          es: "Email incorrecto",
          en: "Incorrect email",
        });
        isAllChecked = false;
      } else {
        element.parentNode.dataset.checked = true;
      }

      break;

    case "user_email":
      element.value = String(element.value).replace(/\s+/g, "");

      if (
        String(element.value).length < 8 ||
        String(element.value).length > 60
      ) {
        element.parentNode.dataset.checked = false;
        isAllChecked = false;
        break;
      } else if (!validateEmail(String(element.value))) {
        element.parentNode.dataset.checked = false;
        isAllChecked = false;
      } else {
        element.parentNode.dataset.checked = true;
      }

      break;

    case "message":

      if (
        String(element.value).length < 1 ||
        String(element.value).length > 400
      ) {
        element.parentNode.dataset.checked = false;
        isAllChecked = false;
      } else {
        element.parentNode.dataset.checked = true;
      }

      break;

    case "file":
      let file = element.files[0];
      if (file) {
        if (
          ![
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/msword",
          ].includes(file.type)
        ) {
          element.value = "";
          element.parentNode.dataset.checked = false;
          element.parentNode.querySelector(".content").innerHTML = translator(
            langCurrent,
            {
              es: FILE_INPUT_DEFAULT_MESSAGE.message.es,
              en: FILE_INPUT_DEFAULT_MESSAGE.message.en,
            }
          );

          isAllChecked = false;
        } else if (file.size > 4 * 1024 * 1024) {
          element.value = "";
          element.parentNode.dataset.checked = false;
          element.parentNode.querySelector(".content").innerHTML = translator(
            langCurrent,
            {
              es: FILE_INPUT_DEFAULT_MESSAGE.message.es,
              en: FILE_INPUT_DEFAULT_MESSAGE.message.en,
            }
          );

          isAllChecked = false;
        } else {
          const inputFileWidth = document.getElementById('file').offsetWidth;
          const maxFileNameLength = inputFileWidth > 330 ? 20 : 15;
          const extension = file.name.split('.').pop();

          let truncatedFileName = file.name;

          if (truncatedFileName.length > maxFileNameLength) {
            truncatedFileName = truncatedFileName.substring(0, maxFileNameLength) + '...' + extension;
          }

          element.parentNode.dataset.checked = true;
          element.parentNode.querySelector(".content").innerHTML = truncatedFileName;
        }
      } else {
        element.parentNode.dataset.checked = false;
        isAllChecked = false;
      }
      break;

    case "checkPrivacy":
      if (element.checked === false) {
        element.parentNode.dataset.checked = false;
        isAllChecked = false;
      } else {
        element.parentNode.dataset.checked = true;
      }

      break;

    default:
      break;
  }
  return isAllChecked;
}

export function validateEmail(mail) {
  let filter =
    /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/; // eslint-disable-line no-useless-escape
  return String(mail).search(filter) !== -1;
}

// TODO: вынести функции валидации в отдельный компонент, и ипортирвоать тольок необходимые
// eslint-disable-next-line no-unused-vars
function usernameIsValid(username) {
  return /[0-9]+$/.test(username);
}
